module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'VPEAS', // Navigation and Site Title
  titleAlt: 'VPEAS économie de la construction', // Title for JSONLD
  description: 'desc here',
  headline: '', // Headline for schema.org JSONLD
  url: 'https://vpeas.netlify.com', // Domain of your site. No trailing slash!
  banner: 'https://images.prismic.io/vpeas/2be15a1a-b3e6-467c-8924-c653fa1ddb04_poster-logo-web.jpg?auto=compress,format', // Used for SEO
  ogLanguage: 'fr_FR', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/icons/favicon.ico', // Used for manifest favicon generation
  shortName: 'aeai', // shortname for manifest. MUST be shorter than 12 characters
  author: 'aeai', // Author for schemaORGJSONLD
  themeColor: '#97D3FF',
  backgroundColor: '#ffffff',

  instagram: '',
  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: '',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
