import React, { useState, useEffect, useContext } from "react"
import PubSub from "pubsub-js"
import { FiltersContext } from "./Layout"

const ProjectFilterProgram = props => {
  const [active, setActive] = useState(false)
  const _FiltersContext = useContext(FiltersContext)
  const { filters, setFilters } = _FiltersContext
  // console.log(props.type,filters)

  useEffect(() => {
    // console.log(filters.program)
    if (active) {
      // console.log(props.value, filters.program)
      if (props.value !== filters.program[0]) setActive(false)
    }
  }, [filters])

  useEffect(() => {
    _update()
  }, [active])

  const _toggle = () => {
    setActive(!active)
  }

  const _update = () => {
    PubSub.publish("FILTER_ACTIVE", active)
    let newFilters = filters["program"]
    // console.log("_update", props.value)
    newFilters = active
      ? [props.value]
      : newFilters.filter(el => el != props.value)

    setFilters(filters => ({
      ...filters,
      program: newFilters,
    }))
  }
  // console.log(filters[props.type])
  const _className = active ? "is-active" : ""

  return (
    <div className={`option ${_className}`} onClick={_toggle}>
      {props.value}
    </div>
  )
}

export default ProjectFilterProgram
