import React, { useContext } from "react"
import { WrapperContext } from "./Layout"
import { arrayUniq } from "../core/utils"
import ProjectFilterProgram from "./ProjectFilterProgram"

const ProjectProgram = () => {
  const _WrapperContext = useContext(WrapperContext)
  const { projects } = _WrapperContext

  const _renderProgram = () => {
    const values = projects.edges.map(({ node }) => {
      let returnValue = node.data.program
      // console.log(returnValue)
      if (node.data.program.indexOf(",") > -1) {
        returnValue = node.data.program.split(",")
      }
      return returnValue
    })
    const arr1d = [].concat(...values) //2D arr > 1d
    // console.log(arr1d)
    return arrayUniq(arr1d)
  }

  return (
    <div className="program x xje subheadline m-only">
      {_renderProgram().map((program, i) => (
        <ProjectFilterProgram key={i} type="program" value={program} />
      ))}
    </div>
  )
}

export default ProjectProgram
