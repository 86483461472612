export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

export function arrayUniq(arr) {
  return arr.filter((el, i, a) => i === a.indexOf(el))
}

export function isElementInViewport(wrapper, el) {
  // const wrapperRect = wrapper.getBoundingClientRect();
  const elRect = el.getBoundingClientRect()
  // console.log(elRect.left, elRect.right, wrapperRect.width)
  return elRect.left >= 0 && elRect.left <= window.innerWidth
}

export function throttle(delay, fn) {
  let lastCall = 0
  return function(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

export function is_touch_device() {
  return "ontouchstart" in window
}
