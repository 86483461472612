import React from 'react';

const BackToTop = () => {
    const _backTop = () => {
        window.scroll(0, 0)
    }

    return (
        <div className="backtop xs-only fXL" onClick={_backTop}>
            <i></i>
        </div>
    );
};

export default BackToTop;