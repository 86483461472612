import React, { useEffect } from "react"
// import { arrayUniq } from "../core/utils"
// import { WrapperContext } from "./Layout"
import ProjectYears from "./ProjectYears"
import ProjectLocations from "./ProjectLocations"
import ProjectProgramMobile from "./ProjectProgramMobile"

const ProjectFilters = () => {
  // const filters = useRef();

  useEffect(() => {
    // window.addEventListener("scroll", _onScroll)
    window.addEventListener("resize", _onResize)
    // _onScroll()
    _onResize()
    return () => {
      // window.removeEventListener("scroll", _onScroll)
      window.removeEventListener("resize", _onResize)
    }
  }, [])

  const _onScroll = () => {
    const { bottom } = document.querySelector("header").getBoundingClientRect()
    console.log(bottom)
    document.querySelectorAll(".dropdown-values").forEach(el => {
      el.style.top = bottom + "px"
    })
    // console.log(bottom)
  }

  const _onResize = () => {
    // const headerHeight = document.querySelector("header").getBoundingClientRect().height
    const { width } = document.querySelector(".card").getBoundingClientRect()
    document.querySelectorAll(".dropdown-values").forEach(el => {
      el.style.width = width - 8 + "px"
    })
  }

  return (
    <div className="projects-filters cont x ">
      <ProjectProgramMobile />
      <ProjectYears />
      <ProjectLocations />
    </div>
  )
}

export default ProjectFilters
