import React, { useEffect, useState, useContext, useRef } from "react"
import PubSub from "pubsub-js"
import { arrayUniq } from "../core/utils"
import i18n from "../../config/i18n"
import { WrapperContext, FiltersContext } from "./Layout"
import ProjectFilter from "./ProjectFilter"

const ProjectLocations = () => {
  const [active, setActive] = useState(false)
  const _WrapperContext = useContext(WrapperContext)
  const { locations } = _WrapperContext

  const _FiltersContext = useContext(FiltersContext)
  const { filters } = _FiltersContext

  const dropdownValues = useRef();
  // const _FiltersContext = useContext(FiltersContext)
  // const { filters, isFiltering } = _FiltersContext

  //sort alpha
  locations.edges.sort((a, b) => {
    const aText = a.node.data.titre.text
    const bText = b.node.data.titre.text
    return aText < bText ? -1 : aText > bText ? 1 : 0
  })

  useEffect(() => {
    const tokenO = PubSub.subscribe("FILTER_OPEN", (e, d) => {
      if (d.type !== "location") setActive(false)
    })

    const tokenA = PubSub.subscribe("FILTER_ACTIVE", () => {
      setActive(false)
    })

    return () => {
      PubSub.unsubscribe(tokenO)
      PubSub.unsubscribe(tokenA)
    }
  }, [])

  useEffect(() => {
    if (active) {
      PubSub.publish("FILTER_OPEN", { type: "location" })
      // console.log(dropdownValues.current)
      // dropdownValues.current.focus()
    }else{
      PubSub.publish("FILTER_CLOSE", { type: "location" })
    }
  }, [active])

  const _toggle = () => {
    setActive(!active)
  }

  const _getAllSticky = () => {
    const sticky = locations.edges.filter(({ node }) => {
      return node.data.sticky
    })

    const values = sticky.map(({ node }) => {
      return node.data.titre.text
    })
    return arrayUniq(values)
  }

  const _getAllNonSticky = () => {
    const nonSticky = locations.edges.filter(({ node }) => {
      return !node.data.sticky
    })
    const values = nonSticky.map(({ node }) => {
      return node.data.titre.text
    })
    return arrayUniq(values)
  }

  const activeClass = active ? "is-active" : ""
  const filterActiveClass = filters.location.length > 0 ? "is-filter-active" : ""
  // if(isFiltering && filters.location.length > 0)activeClass = ""
  // console.log("isFiltering", isFiltering)
  return (
    <div className={"dropdown " + activeClass}>
      <div className={`dropdown-label subheadline ${filterActiveClass}`} onClick={_toggle}>
        {i18n["fr-fr"]["location"]}
      </div>
      <div className="dropdown-values headline" ref={dropdownValues}>
        {_getAllSticky().map((option, i) => (
          <div className={`dropdown-value--item `} key={i}>
            <ProjectFilter type="location" value={option} />
          </div>
        ))}
        {/* <div className="sep1"></div> */}
        {_getAllNonSticky().map((option, i) => (
          <div className={`dropdown-value--item `} key={i}>
            <ProjectFilter type="location" value={option} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectLocations
