import React, { createContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"

const WrapperContext = createContext()
const FiltersContext = createContext()

const Layout = ({ children, pageContext: { template } }) => {
  const { options, projects, locations } = useStaticQuery(query)
  const [filters, setFilters] = useState({
    program: [],
    year: [],
    location: [],
  })
  // console.log(filters)
  // const isFiltering =
  //   filters.program.length > 0 ||
  //   filters.year.length > 0 ||
  //   filters.location.length > 0

  // const pageClass = isFiltering() ? "is-filtering" : ""
  // const bodyClass = isFiltering ? "is-filter-open" : ""
  // console.log(filters)

  useEffect(() => {
    const tokenO = PubSub.subscribe("FILTER_OPEN", (e, d) => {
      // console.log(e)
      // setTimeout(() => {
      //   if('ontouchstart' in window && window.innerWidth < 768){
      //     document.body.classList.add("is-filter-open")
      //   }
      // }, 400)
    })
    const tokenC = PubSub.subscribe("FILTER_CLOSE", (e, d) => {
      // console.log(e)
      // document.body.classList.remove("is-filter-open")
    })

    const token = PubSub.subscribe("ROUTE_UPDATE", e => {
      // console.log(e)
      setFilters({
        program: [],
        year: [],
        location: [],
      })
      PubSub.publish("FILTER_ACTIVE", false)
    })

    return () => {
      // PubSub.unsubscribe(tokenF)
      PubSub.unsubscribe(tokenO)
      PubSub.unsubscribe(tokenC)
      PubSub.unsubscribe(token)
    }
  })

  // useEffect(() => {
  //   if(isFiltering){
      
  //   }else{

  //   }
  // }, [filters])

  const isFiltering = () => {
    return filters.program.length > 0 
    || filters.year.length > 0 
    || filters.location.length > 0
  }

  const pageClass = isFiltering() ? "is-filtering" : ""

  return (
    <WrapperContext.Provider value={{ options, projects, locations }}>
      <FiltersContext.Provider value={{ filters, setFilters, isFiltering }}>
        <div id="page">
          <Header isHome={template === "home"} />
          <main className={pageClass}>{children}</main>
          <Footer />
        </div>
      </FiltersContext.Provider>
    </WrapperContext.Provider>
  )
}

export { Layout, WrapperContext, FiltersContext }

const query = graphql`
  query {
    options: prismicOptions {
      ..._prismicOptions
    }
    projects: allPrismicProject {
      edges {
        node {
          uid
          data {
            ...projet
          }
        }
      }
    }
    locations: allPrismicLocation {
      edges {
        node {
          data {
            ...location
          }
        }
      }
    }
  }
`
