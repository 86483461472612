import React from "react"

const Footer = () => (
  <footer>
    <div className="x xjb">
      <div className="col">
        <div className="copyright">© {new Date().getFullYear()} </div>
      </div>
      <div className="col">
        <p>
          Made with ❤ by the{" "}
          <a href="ahmedghazi.com" target="_blank">
            a_e_a_i_
          </a>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer
