import React, { useState, useEffect, useContext } from "react"
import PubSub from "pubsub-js"
import { FiltersContext } from "./Layout"

const ProjectFilter = props => {
  const [active, setActive] = useState(false)
  // const [filters, setFilters] = useContext(FiltersContext);
  const _FiltersContext = useContext(FiltersContext)
  const { filters, setFilters } = _FiltersContext
  // console.log(props.type,filters)

  useEffect(() => {
    _update()
  }, [active])

  const _toggle = () => {
    setActive(!active)
  }

  const _update = () => {
    PubSub.publish("FILTER_ACTIVE", active)
    // PubSub.publish("FILTER", {type: props.type, value: payload})
    let newFilters = filters[props.type]
    if (active) {
      newFilters.push(props.value)
    } else {
      newFilters = newFilters.filter(el => el != props.value)
    }

    setFilters(filters => ({
      ...filters,
      [props.type]: newFilters,
    }))
  }
  // console.log(filters[props.type])
  const _className = active ? "is-active" : ""
  // if(props.type === "program" && props.value !== filters.program){
  //     console.log("not active")
  //     setActive(false)
  //     _className = ""
  // }

  return (
    <div className={`option ${_className}`} onClick={_toggle}>
      {props.value}
    </div>
  )
}

export default ProjectFilter
