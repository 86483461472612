import React, { useEffect, useState, useContext, useRef } from "react"
import PubSub from "pubsub-js"
import { arrayUniq } from "../core/utils"
import i18n from "../../config/i18n"
import { WrapperContext, FiltersContext } from "./Layout"
import ProjectFilter from "./ProjectFilter"

const ProjectYears = () => {
  const [active, setActive] = useState(false)
  // const [filterActive, setFilterActive] = useState()
  const _WrapperContext = useContext(WrapperContext)
  const { projects } = _WrapperContext

  const _FiltersContext = useContext(FiltersContext)
  const { filters } = _FiltersContext

  const dropdownValues = useRef();
  
  useEffect(() => {
    const tokenO = PubSub.subscribe("FILTER_OPEN", (e, d) => {
      if (d.type !== "year") setActive(false)
    })

    const tokenA = PubSub.subscribe("FILTER_ACTIVE", () => {
      setActive(false)
    })

    return () => {
      PubSub.unsubscribe(tokenO)
      PubSub.unsubscribe(tokenA)
    }
  }, [])

  useEffect(() => {
    if (active){
      PubSub.publish("FILTER_OPEN", { type: "year" })
      // dropdownValues.current.focus()
    }else{
      PubSub.publish("FILTER_CLOSE", { type: "year" })
    }
  }, [active])

  const _toggle = () => {
    setActive(!active)
  }

  const _renderProjectYears = () => {
    const values = projects.edges.map(({ node }) => {
      // console.log(node.data.year)
      return node.data.year
    })
    const uniqueValues = arrayUniq(values)
    return uniqueValues.sort((a, b) => b - a)
  }

  // console.log(filters.year)
  const activeClass = active ? "is-active" : ""
  const filterActiveClass = filters.year.length > 0 ? "is-filter-active" : ""
  
  return (
    <div className={"dropdown " + activeClass}>
      <div className={`dropdown-label subheadline ${filterActiveClass}`} onClick={_toggle}>
        {i18n["fr-fr"]["year"]}
      </div>
      <div className="dropdown-values headline" ref={dropdownValues}>
        {_renderProjectYears().map((option, i) => (
          <div className={`dropdown-value--item `} key={i}>
            <ProjectFilter type="year" value={option} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectYears
