//const website = require('./website')

module.exports = {
    'fr-fr': {
      default: true,
      label: 'Fr',
      path: 'fr',
      locale: 'fr-fr',
      siteLanguage: 'fr',
      ogLang: 'fr_FR',
      year: 'année',
      location: 'lieu',
      program: 'programme'
    },
    'en-gb': {
      default: false,
      label: 'En',
      path: 'en',
      locale: 'en-gb',
      siteLanguage: 'en',
      ogLang: 'en_GB' ,
      year: 'year',
      location: 'location',
      program: 'program'
    }
  }
  