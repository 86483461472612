import React, { useEffect, useContext } from "react"
//import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
// import TransitionLink from "gatsby-plugin-transition-link"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { is_touch_device } from '../core/utils'
import Website from "../../config/website"
import { WrapperContext } from "./Layout"
import ProjectProgram from "./ProjectProgram"
import BackToTop from "./ui/BackToTop"
import ProjectFilters from "../components/ProjectFilters"
// import Burger from "./ui/Burger"

// const logo = require("../images/logo-web.svg")
import Logo from "../images/vpeas-logo.inline.svg";

const Header = props => {
  const _WrapperContext = useContext(WrapperContext)
  const { options } = _WrapperContext

  useEffect(() => {
    window.addEventListener("scroll", _onScroll)
    _onScroll()

    return () => {
      window.removeEventListener("scroll", _onScroll)
    }
  }, [])

  const _onScroll = () => {
    // console.log(window.pageYOffset)
    if (window.pageYOffset > 10) {
      document.body.classList.add("affix")
    } else {
      document.body.classList.remove("affix")
    }
  }

  const _logoClick = () => {
    // PubSub.publish("ROUTE_UPDATE")
    // navigate("/")
    document.location.assign("/")
  }
  // console.log(props)
  return (
    <header>
      <div className="x xdc xjb h100">
        <div className="header-top">
          <div className="x xjb fXL fCond">
            <div className="col ">
              <div className="site-title txt-c--" onClick={_logoClick}>
                {/* <Link to="/">{options.data.title.text}</Link> */}
                {/* {Website.title} */}
                {/* <img src={logo} alt={Website.title} height="49"/> */}
                <Logo />
              </div>
            </div>
            <div className="col ">
              <nav className="">
                <ul className="menu x xje ">
                  <li>
                    <Link to="/">PROJETS</Link>
                  </li>
                  <li>
                    <Link to="/a-propos">À PROPOS</Link>
                  </li>
                </ul>
                {props.isHome && <ProjectProgram />}
              </nav>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          {props.isHome && <ProjectFilters />}
        </div>

        <BackToTop />
      </div>
    </header>
  )
}

export default Header
