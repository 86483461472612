import React, { useEffect, useState, useContext } from "react"
import PubSub from "pubsub-js"
import { arrayUniq } from "../core/utils"
import i18n from "../../config/i18n"
import { WrapperContext, FiltersContext } from "./Layout"
import ProjectFilterProgramMobile from "./ProjectFilterProgramMobile"

const ProjectProgramMobile = () => {
  const [active, setActive] = useState(false)
  const _WrapperContext = useContext(WrapperContext)
  const { projects } = _WrapperContext

  const _FiltersContext = useContext(FiltersContext)
  const { filters } = _FiltersContext


  useEffect(() => {
    const tokenO = PubSub.subscribe("FILTER_OPEN", (e, d) => {
      if (d.type !== "program") setActive(false)
    })

    const tokenA = PubSub.subscribe("FILTER_ACTIVE", () => {
      setActive(false)
    })
    return () => {
      PubSub.unsubscribe(tokenO)
      PubSub.unsubscribe(tokenA)
    }
  }, [])

  useEffect(() => {
    if (active) {
      PubSub.publish("FILTER_OPEN", { type: "program" })
    }else{
      PubSub.publish("FILTER_CLOSE", { type: "program" })
    }
  }, [active])

  const _toggle = () => {
    setActive(!active)
  }

  // const _renderProjectYears = () => {
  //     const values = projects.edges.map(({node}) => {
  //         return node.data.program
  //     })
  //     return arrayUniq(values)
  // }
  const _renderProgram = () => {
    const values = projects.edges.map(({ node }) => {
      let returnValue = node.data.program
      // console.log(returnValue)
      if (node.data.program.indexOf(",") > -1) {
        returnValue = node.data.program.split(",")
      }
      return returnValue
    })
    const arr1d = [].concat(...values) //2D arr > 1d
    // console.log(arr1d)
    return arrayUniq(arr1d)
  }

  const activeClass = active ? "is-active" : ""
  const filterActiveClass = filters.program.length > 0 ? "is-filter-active" : ""

  return (
    <div className={"dropdown xs-only " + activeClass}>
      <div className={`dropdown-label subheadline ${filterActiveClass}`} onClick={_toggle}>
        {i18n["fr-fr"]["program"]}
      </div>
      <div className="dropdown-values headline">
        {_renderProgram().map((option, i) => (
          <div className={`dropdown-value--item `} key={i}>
            <ProjectFilterProgramMobile type="program" value={option} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectProgramMobile
